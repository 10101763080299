import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { LocationContext } from '../LocationContext/LocationContext';
import LocationListItem from './LocationListItem';

//styles
import styles from './LocationList.module.scss';

//Icons
import IconChevronDown from '../../assets/images/featherIcons/IconChevronDown';
import InnerIcon from '../LocationMap/InnerIcon';

// Helper
import i18n from '../../i18n';
import ColorMapping from '../LocationMap/ColorMapping';

const Trigger = ({ data }) => {
    return (
        <span className={styles.locationListItem}>
            <span
                className={styles.colorPill}
                style={{ backgroundColor: data.color }}
            />
            <span className={styles.contentContainer}>{data.title}</span>
            <span className={styles.arrow}>
                <IconChevronDown aria-hidden />
            </span>
        </span>
    );
};

Trigger.propTypes = {
    data: PropTypes.objectOf(PropTypes.string),
};

const LocationList = () => {
    const { locationObjects } = useContext(LocationContext);
    const mappedListItems = useMemo(() => {
        const clusteredLocationsDOM = locationObjects.map(
            (locationObject, index) => {
                const { id, categoryId, subCategoryIds, name, description } =
                    locationObject;

                const customStyles = {};

                if (categoryId === 'schule') {
                    customStyles.backgroundColor =
                        ColorMapping[
                            (subCategoryIds || []).find((catId) => {
                                return Boolean(ColorMapping[catId]);
                            })
                        ] || '#1A76D0';
                }

                return (
                    <AccordionItem
                        key={`ai-${id || index}-${index}`}
                        className={`${styles.placeItem} ${styles.accordionItem}`}
                    >
                        <AccordionItemHeading
                            aria-level={4}
                            className={styles.accordionHeading}
                        >
                            <AccordionItemButton>
                                <span
                                    className={styles.flexContainer}
                                    aria-labelledby={`place_title_${id}`}
                                    key={`aib-${id}-${index}`}
                                >
                                    <div
                                        className={[
                                            styles.iconWrapper,
                                            styles[categoryId],
                                        ].join(' ')}
                                        style={customStyles}
                                    >
                                        <InnerIcon
                                            categoryId={categoryId}
                                            subCategoryIds={subCategoryIds}
                                        />
                                    </div>
                                    <div className={styles.titleWrapper}>
                                        <span
                                            className={styles.title}
                                            id={`place_title_${id}`}
                                        >
                                            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                                            {name}
                                        </span>
                                        <span className={styles.description}>
                                            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                                            {description}
                                        </span>
                                    </div>
                                    <span className={styles.arrow}>
                                        <IconChevronDown aria-hidden />
                                    </span>
                                </span>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <LocationListItem locationObject={locationObject} />
                        </AccordionItemPanel>
                    </AccordionItem>
                );
            }
        );

        return (
            <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
                <h2 className={styles.headline}>{i18n.t('filters.results')}</h2>
                {clusteredLocationsDOM}
            </Accordion>
        );
    }, [locationObjects]);

    return (
        <div className={styles.categoryListWrapper} id="locationCategoryList">
            {mappedListItems}
        </div>
    );
};

export default LocationList;
