import React from 'react';
import PropTypes from 'prop-types';

// Icons
import IconSchool from '../../assets/images/icons/IconSchool';
import IconInstitutionSehbehinderung from '../../assets/images/icons/IconInstitutionSehbehinderung';
import IconKoopv from '../../assets/images/icons/IconKoopv';
import IconInstitution from '../../assets/images/icons/IconInstitution';
import IconInstitutionSchulAufsicht from '../../assets/images/icons/IconInstitutionSchulAufsicht';
import IconInstitutionLandesinkluClearing from '../../assets/images/icons/IconInstitutionLandesinkluClearing';
import IconInstitutionServiceStelle from '../../assets/images/icons/IconInstitutionServiceStelle';
import IconSchuleBeratungsstelle from '../../assets/images/icons/IconSchuleBeratungsstelle';
import IconLSJ from '../../assets/images/icons/IconLSJ.png';

import styles from './InnerIcon.module.scss';

const InnerIcon = ({ className = '', categoryId, subCategoryIds }) => {
    if (categoryId === 'schule') {
        if (subCategoryIds.includes('schoolCounselingCenter')) {
            return <IconSchuleBeratungsstelle className={className} />;
        }

        return <IconSchool className={className} />;
    }

    if (categoryId === 'kooperationsverbund') {
        return <IconKoopv className={className} />;
    }

    if (categoryId === 'einrichtung') {
        if (subCategoryIds.includes('Sehbehinderte')) {
            return (
                <IconInstitutionSehbehinderung
                    stroke="#000"
                    className={className}
                />
            );
        }

        if (subCategoryIds.includes('institutionClearing')) {
            return (
                <IconInstitutionLandesinkluClearing
                    className={[className, styles.clearing].join(' ')}
                />
            );
        }

        if (subCategoryIds.includes('institutionSchoolSupervision')) {
            return <IconInstitutionSchulAufsicht className={className} />;
        }

        if (subCategoryIds.includes('LSJ_Sachsen')) {
            return (
                <img
                    alt={
                        'Unterstützungsangebot zur Umsetzung inklusiver Schulentwicklung Logo'
                    }
                    height={'36px'}
                    className={[
                        className,
                        styles.lsjImage,
                        styles.image,
                        styles.img,
                    ].join(' ')}
                    src={IconLSJ}
                />
            );
        }

        if (subCategoryIds.includes('institutionSchoolServiceCenter')) {
            return <IconInstitutionServiceStelle className={className} />;
        }

        return <IconInstitution className={className} />;
    }
};

InnerIcon.propTypes = {
    className: PropTypes.string,
    categoryId: PropTypes.string,
    subCategoryIds: PropTypes.arrayOf(PropTypes.string),
};

export default InnerIcon;
