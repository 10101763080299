const MAX_ZOOM = 15;
const MIN_ZOOM = 0;
const DEFAULT_ZOOM = 6.5;
const MAP_DEFAULTS = {
    lat: 50.959545,
    lng: 13.43174,
    zoom: 4.5,
};

export { MAX_ZOOM, MIN_ZOOM, DEFAULT_ZOOM, MAP_DEFAULTS };
